import React, { Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Tag from 'components/tag';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ariaRole } from 'components/util/aria';
import { Mask } from 'components/mask';
import {
  TcIconSetTop,
  TcIconSetHide,
  TcIconSetShow,
  TcIconBlackName,
  TcIconAllowReply,
  TcIconStopReply,
  TcIconDelete,
  TcIconReport,
  TcIconGoodQuestion,
  TcIconTagM,
} from 'components/IconSystem';
import { ActionSheetReport } from 'components/secure-report';

import './more-operations.less';

class MoreOperations extends React.Component {
  constructor(props) {
    super(props);

    this.cancelBtn = createRef();
    this.prevFocusElm = null;
    this.state = {
      confirm_delete: false,
      confirm_report: false,
      confirm_spam: false,
    };
  }

  componentDidUpdate(prevProps) {
    // 如果外部关闭过菜单，清空菜单中的状态
    if (prevProps.showmore && !this.props.showmore) {
      this.resetState();
    }

    // 弹窗显示后聚焦到标题
    if (
      this.props.showmore
      && this.cancelBtn.current
      && typeof this.cancelBtn.current.focus === 'function'
    ) {
      this.prevFocusElm = document.activeElement;
      this.cancelBtn.current.focus();
    }

    if (
      !this.props.showmore
      && prevProps.showmore
      && this.prevFocusElm
      && typeof this.prevFocusElm.focus === 'function'
    ) {
      this.prevFocusElm.focus();
    }
  }

  resetState() {
    this.setState({
      confirm_delete: false,
      confirm_report: false,
      confirm_spam: false,
    });
  }

  /**
     * 点击菜单
     * @param opt   操作对象，top/spam/hide/delete/report
     * @param val   操作的结果，on/off
     */
  onClickMenu({ opt, val }) {
    const { props, state } = this;

    // 删除需要二次确认
    if (opt === 'delete') {
      this.setState({ confirm_delete: true });
      return;
    }

    // 举报需要二次确认
    if (opt === 'report') {
      this.setState({ confirm_report: true });
      return;
    }

    // 加黑需要二次操作
    if (opt === 'spam' && val === 'on') {
      this.setState({ confirm_spam: true });
      return;
    }

    props.onChange({ opt, val });
  }


  renderView() {
    const { state, props } = this;

    const hideOptions = state.confirm_delete || state.confirm_report || state.confirm_spam;
    // if (!props.showmore) {
    //   return null;
    // }
    return (
      <Fragment>
        {props.showmore && (
          <Mask
            onClick={(event) => {
              this.resetState();
              props.onCancel();
            }}
          />
        )}

        <Tag.div
          {...ariaRole('dialog')}
          aria-modal={true}
          aria-labelledby="opera_desc"
          aria-hidden={!props.showmore}
          className={classNames('operations_area', { active: props.showmore })}
        >
          {!hideOptions && (
            <Tag.div className="operations_area_option" >
              <Tag.h6
                className="opera_desc"
                id="opera_desc"
                ref={this.cancelBtn}
                tabIndex="-1"
              >
                {`对“${props.nick_name}”的帖子进行操作`}
              </Tag.h6>
              <Menu
                is_admin={props.is_admin}
                is_admin_post={props.is_admin_post}
                is_owner={props.is_owner}
                is_top={props.is_top}
                is_spam={props.is_spam}
                is_hidden={props.is_hidden}
                is_locked={props.is_locked}
                is_reply={props.is_reply}
                is_good={props.is_good}
                tags={props.tags}
                onClick={res => this.onClickMenu(res)}
              />
            </Tag.div>
          )}

          {state.confirm_delete && (
            <Tag.div className="operations_area_confirm" >
              <Tag.div className="delete_desc">删除后将不可恢复</Tag.div>
              <Tag.div className="delete_btn" onClick={event => props.onDelete()} tabindex={0}>
                确认删除
              </Tag.div>
            </Tag.div>
          )}

          {state.confirm_spam && (
            <Tag.div className="operations_area_confirm">
              {/* <Tag.div className="delete_desc">加入黑名单，该用户所有帖子不再显示；<br /> 且无法参与反馈和评论</Tag.div> */}
              <Tag.div className="delete_desc">加入黑名单，该用户所有帖子不再显示</Tag.div>
              {/* 黑名单参数变为 block */}
              <Tag.div className="delete_btn" onClick={event => props.onChange({ opt: 'block', val: 'on' })}>
                确认加入黑名单
              </Tag.div>
            </Tag.div>
          )}

          {state.confirm_report && (<ActionSheetReport onReport={value => props.onReport(value)} />)}

          <Tag.div
            className="operations_area_cancel else_color"
            {...ariaRole('button')}
            tabindex={0}
            onClick={(evt) => {
              this.resetState();
              props.onCancel();
            }}
          >
            取消
          </Tag.div>
        </Tag.div>
      </Fragment >
    );
  }

  render() {
    return (
      createPortal(
        this.renderView()
        , document.body,
      )
    );
  }
}

MoreOperations.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onReport: PropTypes.func,
  onCancel: PropTypes.func,
  is_admin: PropTypes.bool,
  is_admin_post: PropTypes.bool,
  is_owner: PropTypes.bool,
  nick_name: PropTypes.string,
  is_top: PropTypes.bool,
  is_spam: PropTypes.bool,
  is_hidden: PropTypes.bool,
  is_locked: PropTypes.bool,
  is_reply: PropTypes.bool,
  is_good: PropTypes.bool,
  showmore: PropTypes.bool,
  tags: PropTypes.array,
};

MoreOperations.defaultProps = {
  onChange: () => console.log('onChange 未绑定'),
  onDelete: () => console.log('onDelete 未绑定'),
  onCancel: () => console.log('onCancel 未绑定'),
  onReport: () => console.log('onReport 未绑定'),
  is_admin: false,
  is_admin_post: false,
  is_owner: false,
  is_top: false,
  is_spam: false,
  is_hidden: false,
  is_locked: false,
  is_reply: false,
  is_good: false,
};

const Menu = (props) => {
  if (props.is_admin) {
    return (
      <Tag.ul className="opera_list" >

        {!props.is_reply && (
          <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={event => props.onClick({ opt: 'top', val: props.is_top ? 'off' : 'on' })}>
            <Tag.div className="btn">
              <TcIconSetTop />
            </Tag.div>
            {props.is_top ? '取消置顶' : '置顶'}
          </Tag.li>
        )}

        {!props.is_reply && (
          <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={event => props.onClick({ opt: 'hide', val: props.is_hidden ? 'off' : 'on' })}>
            <Tag.div className="btn">
              {props.is_hidden ? <TcIconSetShow /> : <TcIconSetHide />}
            </Tag.div>
            {props.is_hidden ? '取消隐藏' : '隐藏'}
          </Tag.li>
        )}

        {!props.is_admin_post && (
          <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={event => props.onClick({ opt: 'block', val: props.is_spam ? 'off' : 'on' })}>
            <Tag.div className="btn">
              <TcIconBlackName />
            </Tag.div>
            {props.is_spam ? '取消黑名单' : '加入黑名单'}
          </Tag.li>
        )}

        {!props.is_reply && (
          <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={event => props.onClick({ opt: 'lock', val: props.is_locked ? 'off' : 'on' })}>
            <Tag.div className="btn">
              {props.is_locked ? <TcIconAllowReply /> : <TcIconStopReply />}
            </Tag.div>
            {props.is_locked ? '允许回复' : '禁止回复'}
          </Tag.li>
        )}

        {!props.is_reply && !props.is_tag && (
          <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={(event) => {
            props.onClick({ opt: 'tag', val: props.tags });
          }}>
            <Tag.div className="btn">
              <TcIconTagM />
            </Tag.div>
            标签
          </Tag.li>
        )}

        {!props.is_reply && (
          <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={event => props.onClick({ opt: 'good', val: props.is_good ? 'off' : 'on' })}>
            <Tag.div className="btn">
              <TcIconGoodQuestion />
            </Tag.div>
            {props.is_good ? '取消好问题' : '标为好问题'}
          </Tag.li>
        )}

        <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={event => props.onClick({ opt: 'delete' })}>
          <Tag.div className="btn" >
            <TcIconDelete />
          </Tag.div>
          {'删除'}
        </Tag.li>
      </Tag.ul>
    );
  }

  if (props.is_owner) {
    return (
      <Tag.ul className="opera_list">
        <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={event => props.onClick({ opt: 'delete' })}>
          <Tag.div className="btn" >
            <TcIconDelete />
          </Tag.div>
          {'删除'}
        </Tag.li>
      </Tag.ul>
    );
  }

  return (
    <Tag.ul className="opera_list">
      <Tag.li className="opera_list_item" {...ariaRole('button')} tabindex={0} onClick={event => props.onClick({ opt: 'report' })}>
        <Tag.div className="btn">
          <TcIconReport />
        </Tag.div>
        举报
      </Tag.li>
    </Tag.ul>
  );
};

Menu.propTypes = {
  is_tag: PropTypes.bool,
  /**
     * 是否置顶
     * */
  is_top: PropTypes.bool,
  /**
     * 是否加黑
     * */
  is_spam: PropTypes.bool,
  /**
     * 是否隐藏
     * */
  is_hidden: PropTypes.bool,
  /**
     * 是否禁止回复
     * */
  is_locked: PropTypes.bool,
  /**
     * 当前用户是否管理员
     * */
  is_admin: PropTypes.bool,
  /**
     * 当前帖子是否为管理员的帖子
     * */
  is_admin_post: PropTypes.bool,
  /**
     * 当前用户是否发帖人
     * */
  is_owner: PropTypes.bool,
  /**
     * 当前帖子是否回复
     * */
  is_reply: PropTypes.bool,
  /**
     * 当前帖子是否是好问题
     */
  is_good: PropTypes.bool,
  /**
     * 操作回调
     * */
  onClick: PropTypes.func,
  /**
     * 标记标签
     * */
  tags: PropTypes.array,
};

Menu.defaultProps = {
  is_tag: false,
  is_top: false,
  is_spam: false,
  is_hidden: false,
  is_locked: false,
  is_admin: false,
  is_admin_post: false,
  is_owner: false,
  is_reply: false,
  is_good: false,
  onClick: () => console.log('Menu onClick 未绑定'),
};

export { MoreOperations };
