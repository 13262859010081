import React from 'react';
import PropTypes from 'prop-types';
import { PhotoAlbum } from '@tencent/coral-mobile-comment-list';
import  '@tencent/coral-mobile-comment-list/dist/style/components/photo-album.less';
import './image-mobile.less';
import pxToTaroRem from 'components/util/pxToTaroRem';
import transformImg from 'components/browser-image/transformImg';

/**
 * NOTE: 当前组件小程序实现逻辑不一样 需要单独处理
 */
const ImageMobile = (props) => {
  const handleImageClick = () => {
    if (process.env.TARO_ENV === 'weapp') return;

    setTimeout(() => {
      const previewImgNode = document.getElementsByClassName('preview-image')[0];
      if (!previewImgNode) return;
      const imgNode = previewImgNode.getElementsByTagName('img');
      const imgList = [].slice.call(imgNode);
      imgList.forEach((item) => {
        transformImg(item);
      });
    }, 0);
  };
  return (
    <PhotoAlbum
      data={props.imgList.map(item => item.thumb_url)}
      previewData={props.imgList.map(item => item.original_url)}
      maxLength={pxToTaroRem(130)}
      onClick={handleImageClick}
    />
  );
};


ImageMobile.defaultProps = {
  imgList: [],
  thumb_url: '',
};
ImageMobile.propTypes = {
  imgList: PropTypes.array,
};

export { ImageMobile };
